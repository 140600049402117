import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Modules
import { Redirect, Switch } from "react-router-dom";

import dayjs from "dayjs";

import * as ROUTES from "./Constants/Routes";

import ScrollToTop from "./Components/Utils/hooks/useScrollToTop";
import LoadingLogoFullScreen from "Components/Utils/LoadingLogoFullScreen";
import PrivateRoute from "./Components/Utils/PrivateRoute";

import { PAGE_LOOKUP_KEY } from "./Constants/i18n";

// Trackers
import PixelTrackingProvider from "./lib/fb";
import GoogleAnalyticsProvider from "./lib/ga";
import "./lib/gtm/index";

// Libs
import "./lib/firebase";

// Contexts
import { DetailProvider } from "./Providers/DetailProvider";
import FloatingModalProvider from "./Providers/FloatingModalProvider";
import UserProvider from "./Providers/UserProvider";

// Components
import AuthModal from "./Components/Auth/AuthModal";

// Pages
import SearchPage from "./Components/Booking/Search";
import HomePage from "./Components/Home";

// Booking
import Confirmation from "./Components/Booking/Confirmation";

// Auth
import AuthPage from "./Components/Auth";

// Booking
import ConfirmationRedirect from "./Components/Booking/Confirmation/redirect";
import Payment from "./Components/Booking/Payment";
import BookingPaymentForMobileApp from "./Components/Booking/Payment/BookingPaymentForMobileApp";
import Suite from "./Components/Booking/Suite";

import TranslatedRoute from "Components/Utils/i18n/TranslatedRoute";

// Static
const AboutUs = React.lazy(() => import("Components/AboutUs"));
const ContactUs = React.lazy(() => import("Components/ContactUs"));
const ForRealEstatePartners = React.lazy(() =>
  import("Components/ForRealEstatePartners")
);
const HelpCenter = React.lazy(() => import("Components/HelpCenter"));
const NotFound = React.lazy(() => import("Components/Layout/NotFound"));

// Legals
const PrivacyPolicy = React.lazy(() =>
  import("Components/Legals/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("Components/Legals/TermsAndConditions")
);

// Account
const EditProfile = React.lazy(() => import("Components/Account/EditProfile"));
const Blog = React.lazy(() => import("Components/Blog"));
const BlogPage = React.lazy(() => import("Components/Blog/Page"));

// Corporate
const Corporate = React.lazy(() => import("Components/Corporate"));

// Careers
const Careers = React.lazy(() => import("Components/Careers"));

// Invoice Payment 
const InvoicePayment = React.lazy(() => import("Components/InvoicePayment"))

// TotalPay redirection link
const TotalPayRedirect = React.lazy(() => import("Components/TotalPay/redirect"));

const ExternalPaymentSuccessful = React.lazy(() => import("Components/Modals/ExternalPaymentSuccessful"))

// Add new payment card
const AddPaymentCard = React.lazy(() => import("Components/AddPaymentCard"))

// Dayjs extends
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const Contracts = React.lazy(() => import("./Components/Contracts"));
const ZATCAInvoice = React.lazy(() => import("./Components/ZATCAInvoice"));

require("dotenv").config();
require("./lib/fb");

const App = () => {
  const { i18n } = useTranslation();
  return (
      <PixelTrackingProvider>
        <FloatingModalProvider>
          <UserProvider>
            <GoogleAnalyticsProvider>
              <DetailProvider>
                <Suspense fallback={<LoadingLogoFullScreen />}>
                  <Helmet
                    htmlAttributes={{
                      lang: i18n.language,
                      dir: i18n.dir(),
                    }}
                  />
                  <AuthModal />

                  {/* App */}
                  <div className="app d-flex flex-column min-vh-100">
                    <ScrollToTop />
                    <Switch>
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.HOMEPAGE}
                        exact
                        path={ROUTES.HOME}
                        component={HomePage}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.PAYMENT_SUCCESSFULLY_PROCESSED}
                        component={ExternalPaymentSuccessful}
                        componentProps={{messageKey: "successful_payment"}}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.CARD_SUCCESSFULLY_ADDED}
                        component={ExternalPaymentSuccessful}
                        componentProps={{messageKey: "successful_adding_card"}}
                      />
                      {/* Contracts */}
                      <TranslatedRoute
                        exact
                        path={ROUTES.CONTRACTS}
                        component={Contracts}
                      />
                      {/* ZATCA invoice */}
                      <TranslatedRoute
                        exact
                        path={ROUTES.ZATCA_INVOICE}
                        component={ZATCAInvoice}
                      />
                      {/* AUTH */}
                      <TranslatedRoute
                        exact
                        path={ROUTES.LOGIN}
                        component={AuthPage}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.FORGOT_PASSWORD}
                        component={AuthPage}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.SIGN_UP}
                        component={AuthPage}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.SIGN_UP_WITH_EMAIL}
                        component={AuthPage}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.VERIFY_BIRTHDATE}
                        component={AuthPage}
                      />
                      {/* BOOKING */}
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.SEARCH_RESULTS}
                        path={ROUTES.SEARCH}
                        component={SearchPage}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.LISTING_PAGE}
                        path={`${ROUTES.SUITE}/:id/:friendlyID`}
                        component={Suite}
                      />
                      {/* https://stellastays-com-dev.web.app/booking-payment-from-mobile/9/2023-08-22/2023-09-21/1?token=xyz */}
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.BOOKING_PAYMENT}
                        path={`${ROUTES.BOOKING_PAYMENT_MOBILE_APP}/:id/:startDate/:endDate/:numberOfGuests`}
                        exact
                        component={BookingPaymentForMobileApp}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.BOOKING_PAYMENT}
                        path={`${ROUTES.BOOKING_PAYMENT}/:id/:startDate/:endDate/:numberOfGuests`}
                        exact
                        component={Payment}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.BOOKING_CONFIRMATION}
                        path={`${ROUTES.PAYMENT_SUCCESS_REDIRECT}/:id`}
                        component={ConfirmationRedirect}
                      />
                      <PrivateRoute
                        lookupKey={PAGE_LOOKUP_KEY.BOOKING_CONFIRMATION}
                        path={`${ROUTES.BOOKING_CONFIRMATION}/:id`}
                        component={Confirmation}
                      />
                      <Redirect exact from={ROUTES.BLOG} to={ROUTES.PRESS} />
                      <Redirect
                        exact
                        from={`${ROUTES.BLOG}/:id`}
                        to={`${ROUTES.PRESS}/:id`}
                      />
                      {/* Blog */}
                      <TranslatedRoute
                        exact
                        path={ROUTES.PRESS}
                        component={Blog}
                      />
                      <TranslatedRoute
                        exact
                        path={`${ROUTES.PRESS}/:id`}
                        component={BlogPage}
                      />
                      {/* LEGALS */}
                      <TranslatedRoute
                        exact
                        path={ROUTES.TERMS_AND_CONDITIONS}
                        component={TermsAndConditions}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.HELP_CENTER}
                        exact
                        path={[ROUTES.HELP_CENTER, ROUTES.HELP_CENTER_SEARCH]}
                        component={HelpCenter}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.CONTACT_US}
                        exact
                        path={ROUTES.CONTACT_US}
                        component={ContactUs}
                      />
                      <TranslatedRoute
                        exact
                        path={ROUTES.PRIVACY_POLICY}
                        component={PrivacyPolicy}
                      />
                      {/* ACCOUNT */}
                      <PrivateRoute
                        path={ROUTES.EDIT_PROFILE}
                        exact
                        component={EditProfile}
                      />
                      {/* MENU */}
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.ABOUT_US}
                        exact
                        path={ROUTES.ABOUT_US}
                        component={AboutUs}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.CORPORATE}
                        exact
                        path={ROUTES.CORPORATE}
                        component={Corporate}
                      />
                      <Redirect
                        exact
                        from={ROUTES.LEASE_TO_STELLA_STAYS}
                        to={ROUTES.FOR_REAL_ESTATE_PARTNERS}
                      />
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.FOR_REAL_ESTATE_PARTNERS}
                        path={ROUTES.FOR_REAL_ESTATE_PARTNERS}
                        exact
                        component={ForRealEstatePartners}
                      />

                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.ABOUT_US}
                        exact
                        path={ROUTES.CAREERS}
                        component={Careers}
                      />
                      {/* Invoice payment */}
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.INVOICE_PAYMENT}
                        exact
                        path={`${ROUTES.INVOICE_PAYMENT}/:id`}
                        component={InvoicePayment}
                      />
                      {/* Add payment card */}
                      <TranslatedRoute
                        lookupKey={PAGE_LOOKUP_KEY.ADD_PAYMENT_CARD}
                        exact
                        path={`${ROUTES.ADD_PAYMENT_CARD}/:id`}
                        component={AddPaymentCard}
                      />
                        {/* TotalPay redirection link */}
                        <TranslatedRoute
                            lookupKey={PAGE_LOOKUP_KEY.INVOICE_PAYMENT}
                            exact
                            path={`${ROUTES.TOTALPAY_PAYMENT}/:id`}
                            component={TotalPayRedirect}
                        />
                      {/* If nothing match, return 404 */}
                      <TranslatedRoute component={NotFound} />
                    </Switch>

                    {/* Footer */}
                  </div>
                </Suspense>
              </DetailProvider>
            </GoogleAnalyticsProvider>
          </UserProvider>
        </FloatingModalProvider>
      </PixelTrackingProvider>
  );
};

export default App;
