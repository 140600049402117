export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const NAME_PATTERN =
  /^(?:[a-zA-Z\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,30}$/;

export const NEW_PASSWORD_PROPERTIES = {
  focused: false,
  value: "",
  validation: { minDigit: false, upperCase: false, lowerCase: false },
  strength: "low",
  isValid: false,
};

export const DATE_OF_BIRTH_FORMAT = "DD/MM/YYYY";

export const AGE_LIMIT = 18;

export const ERROR_MESSAGES = {
  unknown: "error_unknown",
  email: {
    inUse: "error_email_in_use",
    invalidFormat: "error_email_valid",
    required: "error_email_missing",
  },
  mobile: {
    invalidFormat: "error_phone_number_correct",
    required: "error_phone_number",
  },
  dateOfBirth: {
    underAge: `date_of_birth_notice`,
    invalidFormat: "error_date_of_birth_valid",
    required: "error_birthdate_missing",
  },
  password: {
    required: "error_password",
  },
  passwordConfirm: {
    required: "error_password_confirm",
    doesNotMatch: "error_password_match",
  },
  firstName: {
    required: "error_first_name_missing",
  },
  lastName: {
    required: "error_last_name_missing",
  },
};
