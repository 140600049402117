export const HOME = "/";
export const CONTRACTS = "/sign-contract";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const SIGN_UP = "/signup";
export const SIGN_UP_WITH_EMAIL = "/signup/email";
export const VERIFY_EMAIL = "/verify-email";
export const VERIFY_BIRTHDATE = "/verify-birthdate";
export const SEARCH = "/search";
export const SUITE = "/suite";
export const BOOKING_PAYMENT = "/booking-payment";
export const BOOKING_PAYMENT_MOBILE_APP = "/booking-payment-from-mobile";
export const PAYMENT_SUCCESS_REDIRECT = "/payment-redirect";
export const BOOKING_CONFIRMATION = "/booking-confirmation";
export const NOT_FOUND = "/not-found";
export const ABOUT_US = "/about-us";
export const CORPORATE = "/corporate";
export const CAREERS = "/careers";
export const PAYMENT_SUCCESSFULLY_PROCESSED = "/payment-successfully-processed";
export const CARD_SUCCESSFULLY_ADDED = "/card-successfully-added";

/**
 * @deprecated LEASE_TO_STELLA_STAYS page is deprecated.
 * Page is renamed to "For Real estate partners". Use FOR_REAL_ESTATE_PARTNERS
 */
export const LEASE_TO_STELLA_STAYS = "/lease-to-stella-stays";
export const FOR_REAL_ESTATE_PARTNERS = "/for-real-estate-partners";
export const CONTACT_US = "/contact-us";
//ZATCA: Zakat, Tax & Customs Authority in Saudi Arabia
export const ZATCA_INVOICE = "/zatca-invoice";

export const HELP_CENTER = "/help-center";
export const HELP_CENTER_SEARCH = `${HELP_CENTER}/search`;

export const BLOG = "/stella-stories";
export const PRESS = "/press";

export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const EDIT_PROFILE = "/edit-profile";
export const INSTAGRAM = "https://www.instagram.com/stellastays/";
export const FACEBOOK = "https://www.facebook.com/stellastaysofficial/";
export const LINKEDIN = "https://www.linkedin.com/company/stella-stays/";

export const INVOICE_PAYMENT = "/pay";
export const ADD_PAYMENT_CARD = "/add-card";
export const TOTALPAY_PAYMENT = "/totalpay";
