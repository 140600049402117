import React, { useState, useEffect, useRef } from "react";

// Form
import FormInput from "./FormInput";
import countries from "./Helpers/JSON/Countries.json";

// Functions
import CheckSimilarity from "./Helpers/Functions/CheckSimilarity";
import SortByKey from "./Helpers/Functions/SortByKey";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

const checkExists = (input, lng = "en") => {
  let result = false;

  Object.values(countries).forEach((val) => {
    let similarity = CheckSimilarity(val.name[lng], input);
    if (similarity === 1) {
      result = val.name[lng];
    }
  });

  return result;
};

export default function CountryListInput({
  register,
  error,
  name,
  label,
  defaultValue,
  readOnly,
  showCountryCode = false,
  setSelectedCountry = () => {},
  triggerCountryValidation = () => {},
}) {
  const [isCountriesShown, setCountriesShown] = useState(false);
  const [country, setCountry] = useState(defaultValue);
  const {i18n} = useTranslation();
  const firstRenderRef = useRef(0);
  const countryObject = countries.find(({ name }) => name[i18n.languages[0]] === country);
  const direction = i18n.languages[0] === 'ar' ? 'rtl' : 'ltr';

  const handleFocus = () => {
    if (readOnly) return;

    setCountriesShown(true);
  };

  const handleBlur = (e) => {
    setCountriesShown(false);

    let checkCountry = checkExists(e.target.value, i18n.languages[0]);
    if (checkCountry) {
      setCountry(checkCountry);
      setSelectedCountry(checkCountry);
      return;
    }
    setCountry("");
    setSelectedCountry("");
  };

  const handleChange = (e) => {
    setCountry(e.target.value);
    setSelectedCountry(e.target.value);
  };

  // Trigger country validation after first change 
  useEffect(() => {
    if (firstRenderRef.current) {
      triggerCountryValidation();
    } else {
      firstRenderRef.current++;
    }
  }, [country]);

  return (
    <FormInput
      register={register}
      name={name}
      id={name}
      label={label}
      error={error}
      value={country}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      readOnly={readOnly}
      beforeLabelContent={countryObject?.emoji}
      dir={direction}
    >
      {/* Children */}
      <CountryList
        isCountriesShown={isCountriesShown}
        country={country}
        setCountry={(newCountry) => {
          setSelectedCountry(newCountry);
          setCountry(newCountry);
        }}
        showCountryCode={showCountryCode}
        direction={direction}
      />
    </FormInput>
  );
}

const COUNTRY_LIST_STYLES = {
  HIDE: {
    opacity: "1",
    display: "none",
  },
  FADE: {
    opacity: "0",
    display: "block",
  },
  SHOW: {
    opacity: "1",
    display: "block",
  },
};

const CountryList = ({ setCountry, country, isCountriesShown, showCountryCode, direction = 'ltr' }) => {
  const {i18n} = useTranslation();
  const Countries = countries.map(c => ({...c, name: c.name[i18n.languages[0]]}));

  // SET COUNTRY LIST
  const [similarList, setSimilarList] = useState(SortByKey(Countries, "name"));
  const sortList = () => {
    let list = [];

    if (country) {
      Object.values(Countries).forEach((val) => {
        let similarity = CheckSimilarity(val.name, country);
        
        // first threshold
        if (val.name.toLocaleLowerCase().includes(country.toLocaleLowerCase())) {
          list.push({
            name: val.name,
            code: val.code,
            emoji: val.emoji,
            image: val.image,
            similarity: similarity,
          });
        }
      });

      if (list.length > 0) {
        setSimilarList(SortByKey(list, "similarity").reverse());
        return;
      }

      setSimilarList(SortByKey(Countries, "name"));
      return;
    }
    else {
      setSimilarList(SortByKey(Countries, "name"))
    }
  };
  const debouncedList = debounce(() => sortList(), 200);
  useEffect(debouncedList, [country, debouncedList]);

  // HIDE OR NOT
  const [styles, setStyles] = useState(COUNTRY_LIST_STYLES.HIDE);

  useEffect(() => {
    if (isCountriesShown) {
      setStyles(COUNTRY_LIST_STYLES.SHOW);
    } else {
      setStyles(COUNTRY_LIST_STYLES.FADE);
      setTimeout(() => {
        setStyles(COUNTRY_LIST_STYLES.HIDE);
      }, 150);
    }
  }, [isCountriesShown]);

  return (
    <div className="country-list" style={styles} dir={direction}>
      {similarList.map((country, index) => {
        return (
          <div
            key={index}
            onClick={() => setCountry(country.name)}
            className="country-item px-2 d-flex align-items-center"
          >
            <div className="mx-2" style={{fontSize: 'x-large'}}>
              {country.emoji}
            </div>

            <div>{showCountryCode ? country.code : country.name}</div>
          </div>
        );
      })}
    </div>
  );
};
