import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
// Translation Package
import { useTranslation } from "react-i18next";

// Constants
import * as ROUTES from "../../../Constants/Routes";
import QUERIES from "../../../Constants/Queries";
import {
  NEW_PASSWORD_PROPERTIES,
  DATE_OF_BIRTH_FORMAT,
  EMAIL_PATTERN,
  NAME_PATTERN,
  ERROR_MESSAGES,
} from "../constants";

// Packages
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import firebase from "firebase/app";

import logGaEvent from "../../../lib/ga/logGaEvent";
import redirectAfterLoginSuccess from "Components/Auth/functions/redirectAfterLoginSuccess.js";

// Components
import Loading from "../../Utils/Loading";
import FormInput from "../../Form/FormInput";
import PasswordInfo from "../Helpers/PasswordInfo";

// Providers
import {
  useModal,
  useUpdateModal,
  DEFAULT_STATE as DEFAULT_MODAL,
} from "../../../Providers/FloatingModalProvider";

// Functions
import trimWhiteSpace from "../../Utils/validate";
import validatePhone from "../../Utils/validatePhone";
import checkNewPassword from "../functions/checkNewPassword";
import formatDateOfBirth from "../functions/formatDateOfBirth";
import validateSignupForm from "../functions/validateSignupForm";
import PrimaryButton from "../../Buttons/Primary";
import { URL_PARAMS } from "Constants/globals";
import PhoneNumberInput from "Components/Form/PhoneNumberInput";
import { contactReviewFormState } from "Components/Booking/Payment/Forms/ContactReview/formState";
import { useUser } from "Providers/UserProvider";
import {triggerPaymentMethodSelectionVar} from "./trigger";

const SignUpWithEmailForm = forwardRef((formProps, ref) => {
  const { redirectTo, urlParams, isRoute, routeStateEmail, isInlineSignup=false, setIsSigningUp=()=>{}, isSubscribedToNewsLetter } = formProps;
  //Translation hook
  const { t } = useTranslation();

  // Location data
  const { pathname, search } = useLocation();
  const getSearchParams = new URLSearchParams(search)?.toString();
  let setSearchParams = null;
  if (getSearchParams) {
    setSearchParams = `&${new URLSearchParams(search)?.toString()}`;
  }

  // Handle setting user
  const { setUser } = useUser();

  const history = useHistory();

  const { props } = useModal();
  const updateModal = useUpdateModal();

  // Handle date of birth masking
  const [dateOfBirth, setDateOfBirth] = useState("");
  const handleDateOfBirth = (e) => {
    formatDateOfBirth(e.target.value, setDateOfBirth);
  };
  const handleOnDateOfBirthKeyDown = (e) => {
    const keys = {
      backspace: 8,
    };
    let val = e.target.value;

    // Detect remove
    if (e.which === keys.backspace) {
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        setDateOfBirth(val);
      }
    }
  };

  // Handle password
  const [password, setPassword] = useState(NEW_PASSWORD_PROPERTIES);
  const handlePasswordOnChange = (e) => {
    const pass = e.target.value;
    const validations = checkNewPassword(pass);

    setPassword((p) => ({
      ...p,
      ...validations,
      value: pass,
    }));
  };
  const handlePasswordOnFocus = () => {
    setPassword((p) => ({ ...p, focused: true }));
  };

  const [disabledButton, setDisabledButton] = useState(false);

  // Form handling
  const [mutationError, setMutationError] = useState(null);
  const formRef = useRef(null);
  const { register, handleSubmit, setError, errors, clearErrors, trigger, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: {
      email: isRoute ? routeStateEmail : props?.email,
    },
  });

  const [addNewUser] = useMutation(QUERIES.USER.SET_SIGN_UP);
  const [login] = useMutation(QUERIES.USER.SET_LOGIN);

  const resetDisabledButton = () => {
    // UX feature
    // Add additional waiting to indicate the form is working smoothly
    setTimeout(() => {
      setDisabledButton(false);
      setIsSigningUp(false)
    }, 700);
    return;
  };

  const onSubmit = async (data) => {
    setDisabledButton(true);
    setIsSigningUp(true)
    clearErrors();
    setMutationError(null);

    // Validations and set custom errors
    const validations = validateSignupForm(data, !isInlineSignup);
    const doesAllPass = validations.doesAllPass;
    if (doesAllPass === false) {
      if (!validations.isValidPhoneNumber) {
        setError("mobile", {
          type: "manual",
          message: t(ERROR_MESSAGES.mobile.invalidFormat),
        });
      }
      if (!validations.isDateOfBirthValidAge) {
        setError("dateOfBirth", {
          type: "manual",
          message: t(ERROR_MESSAGES.dateOfBirth.underAge),
        });
      }
      if (!validations.doesDateExist) {
        setError("dateOfBirth", {
          type: "manual",
          message: t(ERROR_MESSAGES.dateOfBirth.invalidFormat),
        });
      }
      resetDisabledButton();
      return;
    }

    // Password validation
    if (password.isValid === false) {
      setError("password", { type: "manual", message: "" });
      resetDisabledButton();
      return;
    }

    // Password confirm validation
    if (data.password !== data.passwordConfirm) {
      setError("passwordConfirm", {
        type: "manual",
        message: t(ERROR_MESSAGES.passwordConfirm.doesNotMatch),
      });
      resetDisabledButton();
      return;
    }

    const mobile = data.mobile.replaceAll(" ", "");

    let payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: mobile,
      password: data.password,
    }
    
    if(!isInlineSignup) {
      const dob = dayjs(data.dateOfBirth, DATE_OF_BIRTH_FORMAT.toUpperCase());
      payload = {
        ...payload,
        birthdate: dob.format("YYYY-MM-DD"),
      };
    }
    else {
      payload = {
        ...payload,
        isSubscribedToNewsLetter
      };
    }

    await addNewUser({ variables: { payload } })
      .then((response) => {
        const jwtToken = response.data.result.jwtToken;
        localStorage.setItem("token", jwtToken);
      })
      .then(async () => {
        // Login and redirect
        await firebase
          .auth()
          .signInWithEmailAndPassword(data.email, data.password)
          .then(async (res) => await res.user.getIdToken())
          .then(async (token) => {
            return await login({ variables: { firebaseToken: token }, update: () => {
              setUser({...payload, birthdate: payload.birthdate ?? ''})
            }});
          })
          .then((res) =>{
            localStorage.setItem("token", res.data.result.jwtToken)
            if(isInlineSignup) {
              contactReviewFormState({
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                mobile: payload.mobile,
              });
              triggerPaymentMethodSelectionVar(payload.email);
            }
          })
          .then(() => {
            logGaEvent("sign_up", "email");

            setTimeout(() => {
              if (redirectTo) {
                redirectAfterLoginSuccess(redirectTo, urlParams);
              } else if(!isInlineSignup) {
                history.go(0);
              }
            }, 300);
          })
          .catch(() => {
            setMutationError(t("error_general"));
            setDisabledButton(false);
            setIsSigningUp(false)
          });

        updateModal(DEFAULT_MODAL);
        return;
      })
      .catch((error) => {
        error = { ...error };
        setMutationError(error.message ?? t("error_general"));
        setDisabledButton(false);
        setIsSigningUp(false)
        window.scrollTo({ top: 0, left: 0 });
        return error;
      });

    setDisabledButton(false);
    setIsSigningUp(false)
  };

  useImperativeHandle(ref, () => {
    return {
      signup() {
        formRef.current?.dispatchEvent(new Event('submit'));
      }
    };
  }, []);

  return (
    <div>
      {/* Had to implement it this way as signup in "booking/payment/:id/:startDate/:endDate/:numberOfGuests" requires same logic in regular signup. Time factor was the most important */}
      {!isInlineSignup ?
      <div className="auth-form">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="auth-form__signup-with-email"
        >
            <div className="row">
              <div className="col-12 ">
                <h6 className="auth-form__form-title">{t("finish_sign_up")}</h6>
              </div>
            </div>

            {mutationError && (
              <div className="row">
                <div className="col-12 auth-form__error">{mutationError}</div>
              </div>
            )}
            {/* Form */}

            <div className="row">
              {/* EMAIL */}
              {/* Email address */}
              <div className="col-12 ">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.email.required),
                    },
                    pattern: {
                      value: EMAIL_PATTERN,
                    },
                    validate: trimWhiteSpace(),
                  })}
                  type="email"
                  name="email"
                  label={t("email")}
                  autoFocus={routeStateEmail || props?.email ? false : ""}
                  error={errors.email}
                  data-test-id="email-input"
                />
              </div>

              {/* Mobile */}
              <div className="col-12">
                <PhoneNumberInput
                  phoneNumberRegister={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.mobile.required),
                    },
                    minLength: {
                      value: 5,
                      message: t(ERROR_MESSAGES.mobile.invalidFormat),
                    },
                    validate: validatePhone,
                  })}
                  autoFocus={routeStateEmail || props?.email ? true : false}
                  phoneNumberError={errors.mobile}
                  countryError={errors.country}
                  getCountry={() => getValues("country")}
                  countryRegister={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.countryCode.required),
                    },
                    validate: trimWhiteSpace,
                  })}
                  triggerCountryValidation={() => trigger("country")}
                />
              </div>

              {/* First name */}
              <div className="col-12">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.firstName.required),
                    },
                    validate: trimWhiteSpace(),
                    pattern: {
                      value: NAME_PATTERN,
                      message: t("error_name_characters"),
                    },
                  })}
                  type="text"
                  name="firstName"
                  label={t("first_name")}
                  error={errors.firstName}
                  data-test-id="first-name"
                />
              </div>
              {/* Last name */}
              <div className="col-12">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.lastName.required),
                    },
                    validate: trimWhiteSpace(),
                    pattern: {
                      value: NAME_PATTERN,
                      message: t("error_name_characters"),
                    },
                  })}
                  type="text"
                  name="lastName"
                  label={t("last_name")}
                  error={errors.lastName}
                  info={t("name_id_notice")}
                  data-test-id="last-name"
                />
              </div>

              {/* Birthdate */}
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <FormInput
                      register={register({
                        required: {
                          value: true,
                          message: t(ERROR_MESSAGES.dateOfBirth.required),
                        },
                        minLength: {
                          value: 10,
                          message: t(ERROR_MESSAGES.dateOfBirth.invalidFormat),
                        },
                      })}
                      inputMode="numeric"
                      type="text"
                      name="dateOfBirth"
                      label={t("date_of_birth")}
                      value={dateOfBirth}
                      onChange={handleDateOfBirth}
                      onKeyDown={handleOnDateOfBirthKeyDown}
                      forceLabelToFloat={true}
                      error={errors.dateOfBirth}
                      placeholder={DATE_OF_BIRTH_FORMAT}
                      data-test-id="date-of-birth"
                    />
                  </div>
                </div>
              </div>

              {/* Password */}
              <div className="col-12">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.password.required),
                    },
                  })}
                  type="password"
                  name="password"
                  label={t("password")}
                  value={password.value}
                  onFocus={handlePasswordOnFocus}
                  onChange={handlePasswordOnChange}
                  autoComplete="new-password"
                  spellCheck="false"
                  error={errors.password}
                  data-test-id="password"
                />

                <PasswordInfo password={password} />
              </div>

              {/* Password confirm*/}
              <div className="col-12">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.passwordConfirm.required),
                    },
                  })}
                  type="password"
                  name="passwordConfirm"
                  label={t("confirm_password")}
                  autoComplete="new-password"
                  spellCheck="false"
                  error={errors.passwordConfirm}
                  data-test-id="confirm-password"
                />
              </div>

              {/* Agreement */}
              <div className="col-12 mt-3">
                <p className="info">
                  {t("signup_agreement")}'{" "}
                  <Link to={ROUTES.TERMS_AND_CONDITIONS} target="_blank">
                    {t("terms_and_conditions")}
                  </Link>{" "}
                  {t("and")}{" "}
                  <Link to={ROUTES.PRIVACY_POLICY} target="_blank">
                    {t("privacy_policy")}
                  </Link>
                  .
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-button mt-1" style={{ display: "block" }}>
                  <PrimaryButton
                    label={
                      disabledButton ? (
                        <Loading light />
                      ) : (
                        t("agree_continue_button")
                      )
                    }
                    disabled={disabledButton}
                    data-test-id="agree-continue-button"
                  />
                </div>
              </div>
            </div>
        </form>
      </div>
      :
      <div style={{marginBottom: '-20px'}}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="auth-form__signup-with-email mb-3"
          ref={formRef}
        >
          <div className="confirm-and-pay__form">
            {/* Title */}
            <div className="form-title mt-0">
              <h5 className="form-title__title">{t("inline_signup_personal_information")}</h5>
            </div>
            <div className="col-12 info" style={{fontSize: 'small'}}>
              <span>{`${t("sign_in_question")} `}</span>
              <Link
                to={`${ROUTES.LOGIN}?${URL_PARAMS.REDIRECT}=${pathname}${
                  setSearchParams ?? ""
                }`}
              >{t("login")}</Link>
            </div>
            <div className="row mt-2">
              {/* First name */}
              <div className="col-12 col-md-6">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.firstName.required),
                    },
                    validate: trimWhiteSpace(),
                    pattern: {
                      value: NAME_PATTERN,
                      message: t("error_name_characters"),
                    },
                  })}
                  type="text"
                  name="firstName"
                  label={t("first_name")}
                  error={errors.firstName}
                  data-test-id="first-name"
                />
              </div>
              {/* Last name */}
              <div className="col-12 col-md-6">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.lastName.required),
                    },
                    validate: trimWhiteSpace(),
                    pattern: {
                      value: NAME_PATTERN,
                      message: t("error_name_characters"),
                    },
                  })}
                  type="text"
                  name="lastName"
                  label={t("last_name")}
                  error={errors.lastName}
                  data-test-id="last-name"
                />
              </div>
            </div>
            <div className="col-12">
              <p className="info mb-3">{t("name_id_notice")}</p>
            </div>
            <div className="row">
              <div className="col-12">
                <PhoneNumberInput
                  phoneNumberRegister={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.mobile.required),
                    },
                    minLength: {
                      value: 5,
                      message: t(ERROR_MESSAGES.mobile.invalidFormat),
                    },
                    validate: validatePhone,
                  })}
                  countryRegister={register({
                    required: true,
                    validate: trimWhiteSpace
                  })}
                  getCountry={() => getValues('country')}
                  triggerCountryValidation={() => trigger('country')}
                  autoFocus={routeStateEmail || props?.email ? true : false}
                  phoneNumberError={errors.mobile}
                  countryError={errors.country}
                  data-test-id="mobile-input"
                  countryColSpan={'col-6'}
                  phoneNumberColSpan={'col-6'}
                />
              </div>
            </div>
            <div className="row">
              {/* Email address */}
              <div className="col-12 col-md-6">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.email.required),
                    },
                    pattern: {
                      value: EMAIL_PATTERN,
                    },
                    validate: trimWhiteSpace(),
                  })}
                  type="email"
                  name="email"
                  label={t("email")}
                  autoFocus={routeStateEmail || props?.email ? false : ""}
                  error={errors.email}
                  data-test-id="email-input"
                />
              </div>
            </div>
            <div className="col-12">
              <p className="info mb-3">{t("inline_signup_email_field_notice")}</p>
            </div>
            <div className="row">
              {/* Password */}
              <div className="col-12 col-md-6">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.password.required),
                    },
                  })}
                  type="password"
                  name="password"
                  label={t("password")}
                  value={password.value}
                  onFocus={handlePasswordOnFocus}
                  onChange={handlePasswordOnChange}
                  autoComplete="new-password"
                  spellCheck="false"
                  error={errors.password}
                  data-test-id="password"
                />
                <PasswordInfo password={password} />
              </div>

              {/* Password confirm*/}
              <div className="col-12 col-md-6">
                <FormInput
                  register={register({
                    required: {
                      value: true,
                      message: t(ERROR_MESSAGES.passwordConfirm.required),
                    },
                  })}
                  type="password"
                  name="passwordConfirm"
                  label={t("confirm_password")}
                  autoComplete="new-password"
                  spellCheck="false"
                  error={errors.passwordConfirm}
                  data-test-id="confirm-password"
                />
              </div>
            </div>
            {mutationError && (
              <div className="row">
                <div className="col-12 auth-form__error fs-6">{mutationError}</div>
              </div>
            )}
          </div>
        </form>
      </div>
      }
    </div>
  );
});

export default SignUpWithEmailForm;
