import React, { useState } from "react";

import { BsExclamationCircleFill } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";

const mergeClasses = (classes) => {
  return Object.values(classes)
    .filter((c) => c !== null)
    .filter((c) => c !== false)
    .filter((c) => c !== undefined)
    .map((c) => c)
    .join(" ");
};

const FormInput = ({
  register,
  type,
  name,
  error,
  errorMessage,
  label,
  id,
  placeholder,
  classSuffix,
  children,
  forceLabelToFloat,
  info,
  showClearBtn,
  onClear,
  beforeLabelContent = "",
  ...props
}) => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const direction = props?.dir ?? i18n.dir();

  id = id || `input-${Math.floor(100000 + Math.random() * 900000)}`;

  // Detect focus
  const [focused, setFocused] = useState(false);
  const handleOnFocus = (e) => {
    setFocused(true);
    if (props?.onFocus) props.onFocus(e);
  };

  const handleOnBlur = (e) => {
    setFocused(false);
    if (props?.onBlur) props.onBlur(e);
  };

  const handleOnClear = (e) => {
    setFocused(false);
    if (onClear) onClear(e);
  };

  // Is password?
  const isPassword = type === "password";
  const [showPass, setShowPass] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  // Form group
  const formGroupClass = mergeClasses({
    default: "form-group floating-label",
    show: (forceLabelToFloat || focused) && "show-floating-label",
    suffix: classSuffix,
    error: error && "error",
  });

  // Input
  const inputClasses = {
    default: "form-control floating-label__text-input ",
    type: isPassword && "password-input",
  };
  const inputClass = mergeClasses(inputClasses);
  const inputProps = {
    type: isPassword ? (showPass ? "text" : type) : type,
    className: inputClass,
    ref: register,
    name: name,
    id: id,
    placeholder: placeholder || " ",
    ...props,
  };
  const textarea = type === "textarea";

  return (
    <div className={formGroupClass}>
      <div className="floating-label__input-area">
        {textarea ? (
          <textarea
            {...inputProps}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            style={{ height: "200px" }}
          ></textarea>
        ) : (
          <input
            {...inputProps}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        )}

        <label
          className={`floating-label__label 
					${textarea ? "textarea-label" : ""}`}
          htmlFor={id}
          dir={direction}
          data-before-label-content={`${beforeLabelContent} `}
        > 
          {label}
        </label>

        {props?.value && showClearBtn && (
          <div
            className={`d-flex flex-row justify-content-center align-items-center position-absolute top-0 bottom-0 py-2 px-3 ${
              direction === "ltr" ? "end-0" : "start-0"
            }`}
            role="button"
            onClick={handleOnClear}
            style={{ transform: "translate(0.1px)" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={15}
              height={15}
              viewBox="0 0 352 512"
              fill="rgba(33, 37, 41, 0.7)"
            >
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
            </svg>
          </div>
        )}
      </div>

      {isPassword && (
        <button
          className="show-password"
          onClick={togglePassword}
          type="button"
        >
          {showPass ? t("hide") : t("show")}
        </button>
      )}

      {children}

      {error && (
        <div className="error-text" hidden={error.message === ""}>
          <span className="error-text__icon">
            <BsExclamationCircleFill className="icon" />
          </span>{" "}
          {t(errorMessage) || t(error.message)}
        </div>
      )}

      {info && <div className="info-text">{info}</div>}
    </div>
  );
};

export default FormInput;
