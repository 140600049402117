export const i18n_TYPES = {
  lookupLocalStorage: "i18nextLng",
  lookupQuerystring: "lng",
};

export const PAGE_LOOKUP_KEY = {
  HOMEPAGE: "Homepage",
  SEARCH_RESULTS: "Search-results",
  LISTING_PAGE: "Listing-page",
  BOOKING_PAYMENT: "Booking-payment",
  BOOKING_CONFIRMATION: "Booking-confirmation",
  HELP_CENTER: "Help-center",
  CONTACT_US: "Contact-us",
  ABOUT_US: "About-us",
  FOR_REAL_ESTATE_PARTNERS: "For-real-estate-partners",
  CORPORATE: "Corporate",
  INVOICE_PAYMENT: "Invoice-payment",
  ADD_PAYMENT_CARD: "Add-payment-card"
};
